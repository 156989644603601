<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-edit fa-mdr"></i> <span class="h5"> Estadísticas del Entrega de EPPS </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LA ENTREGA DE EPPS:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-frown-open fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaEntrega.length}}</span>
                            <br>
                            <span class="text-muted"> Entregas de EPPS registrados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestion de Entregas de EPPS</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="6" class="my-2">
                            <b-button block variant="custom" :to="{name:'Proyectos'}">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br><span>Volver</span>
                            </b-button>
                        </b-col>
                        <b-col cols="6" class="my-2">
                            <b-button block variant="custom" @click="modalRegistrarEntrega = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nueva Entrega de EPPS</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Entregas de EPPS </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="3">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaEntrega" :fields="camposEntrega" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirmodalActualizarEntrega(param)" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>

                                    <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarQuejas(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                                <template v-slot:cell(idProyecto)="param">
                                    {{comboProyecto.find(x => x.idProyecto==param.item.idProyecto).nombre}}
                                </template>
                                <template v-slot:cell(referencias)="data">{{ data.item.referencias | truncate(80)}}</template>

                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalRegistrarEntrega" size="xl" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5">Registro de Entrega de EPPS</span>
            </h6>
            <CButtonClose @click="modalRegistrarEntrega = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarEntrega)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="tour" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tour:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese tour" v-model="datosEntrega.tour"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="nombreTrabajador" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre del trabajador:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nombre del trabajador" v-model="datosEntrega.nombreTrabajador"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="areaTrabajo" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Área de trabajo:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el área de trabajo" v-model="datosEntrega.areaTrabajo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="cargoTrabajador" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Cargo del trabajador:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el cargo del trabajador" v-model="datosEntrega.cargoTrabajador"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="dniTrabajador" :rules="{required: true, numeric: true}" v-slot="validationContext">
                            <b-form-group label="DNI del trabajador:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el dni del trabajador" v-model="datosEntrega.dniTrabajador"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12" class="mt-2">
                        <span class="h6 text-muted">Lista de entregas de EPPS</span>
                        <CButton size="sm" class="float-right" color="dark" @click="agregarActividad()">
                            <i class="fas fa-plus fa-sm"></i> Agregar EPPS
                        </CButton>
                    </b-col>
                    <b-col md="12" class="mt-2">
                        <b-table :items="datosEntrega.listaEPPS" :fields="cabeceraActividades" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">
                            
                            <template v-slot:cell(descripcion)="rowActividad">
                                <b-form-input placeholder="Ingrese descripción" v-model.lazy="rowActividad.item.descripcion" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(motivo)="rowActividad">
                                <b-form-input placeholder="Ingrese motivo" v-model.lazy="rowActividad.item.motivo" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(cantidad)="rowActividad">
                                <b-form-input placeholder="Ingrese cantidad" v-model.lazy="rowActividad.item.cantidad" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(fecha)="rowActividad">
                                <b-form-input type="date" placeholder="Ingrese la fecha" v-model.lazy="rowActividad.item.fecha" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(firma)="row">
                                <b-form-group>
                                    <b-input-group>
                                        <b-input-group-prepend v-if="row.item.urlFirma != ''">
                                                <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="descargarDocumento(row.item.urlFirma)">
                                                    <i class="fas fa-download fa-xs"></i>
                                                </b-button>
                                            </b-input-group-prepend>
                                        <b-form-file class="text-left" ref="file" v-model.lazy="row.item.firma" :placeholder="row.item.nombreFirma == '' ?  'Elija un archivo o arrástrelo aquí...' : row.item.nombreFirma" accept=".png,.jpg,.jpeg,image/*" browse-text="Subir" v-on:change="handleFileUpload"></b-form-file>
                                    </b-input-group>
                                </b-form-group>
                            </template>

                            <template #cell(acciones)="param">
                                <b-button @click="quitarActividad(param)" v-if="datosEntrega.listaEPPS.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </template>
                        </b-table>
                    </b-col>

                    <b-col md="6">
                        <validation-provider name="nombreResponsable" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre del Responsable:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nombre del responsable" v-model="datosEntrega.nombreResponsable"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="cargoResponsable" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Cargo del Responsable:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el cargo del responsable" v-model="datosEntrega.cargoResponsable"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fechaResponsable" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha:" class="mb-2">
                                <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosEntrega.fechaResponsable"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <!--   ******************************************************      -->
                    <b-col md="6">
                        <validation-provider name="firmaResponsable" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Firma:" class="mb-2">
                                <b-input-group>
                                    <b-input-group-prepend v-if="datosEntrega.urlFirmaResponsable != ''">
                                        <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="descargarDocumento(datosEntrega.urlFirmaResponsable)">
                                            <i class="fas fa-download fa-xs"></i>
                                        </b-button>
                                    </b-input-group-prepend>
                                    <b-form-file class="text-left" ref="file" v-model="datosEntrega.firmaResponsable" :placeholder="datosEntrega.nombreFirmaResponsable == '' ?  'Elija un archivo o arrástrelo aquí...' : datosEntrega.nombreFirmaResponsable" accept=".png,.jpg,.jpeg,image/*" browse-text="Subir" v-on:change="handleFileUpload"></b-form-file>
                                </b-input-group>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <!--   ******************************************************      -->

                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarEntrega = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <!--MODEL DE ACTUALIZAR -->
    <CModal :show.sync="modalActualizarEntrega" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar Entrega de EPPS</span></h6>
            <CButtonClose @click="modalActualizarEntrega = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarEntrega)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="tour" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tour:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese tour" v-model="datosActualizarEntrega.tour"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="nombreTrabajador" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre del trabajador:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nombre del trabajador" v-model="datosActualizarEntrega.nombreTrabajador"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="areaTrabajo" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Área de trabajo:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el área de trabajo" v-model="datosActualizarEntrega.areaTrabajo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="cargoTrabajador" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Cargo del trabajador:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el cargo del trabajador" v-model="datosActualizarEntrega.cargoTrabajador"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="dniTrabajador" :rules="{required: true, numeric: true}" v-slot="validationContext">
                            <b-form-group label="DNI del trabajador:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el dni del trabajador" v-model="datosActualizarEntrega.dniTrabajador"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12" class="mt-2">
                        <span class="h6 text-muted">Lista de entregas de EPPS</span>
                        <CButton size="sm" class="float-right" color="dark" @click="agregarActividadActualizar()">
                            <i class="fas fa-plus fa-sm"></i> Agregar EPPS
                        </CButton>
                    </b-col>
                    <b-col md="12" class="mt-2">
                        <b-table :items="datosActualizarEntrega.listaEPPS" :fields="cabeceraActividades" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">
                            
                            <template v-slot:cell(descripcion)="rowActividad">
                                <b-form-input placeholder="Ingrese descripción" v-model.lazy="rowActividad.item.descripcion" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(motivo)="rowActividad">
                                <b-form-input placeholder="Ingrese motivo" v-model.lazy="rowActividad.item.motivo" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(cantidad)="rowActividad">
                                <b-form-input placeholder="Ingrese cantidad" v-model.lazy="rowActividad.item.cantidad" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(fecha)="rowActividad">
                                <b-form-input type="date" placeholder="Ingrese la fecha" v-model.lazy="rowActividad.item.fecha" rows="3" max-rows="6"></b-form-input>
                            </template>
                            <template v-slot:cell(firma)="row">
                                <b-form-group>
                                    <b-input-group>
                                        <b-input-group-prepend v-if="row.item.urlFirma != ''">
                                                <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="descargarDocumento(row.item.urlFirma)">
                                                    <i class="fas fa-download fa-xs"></i>
                                                </b-button>
                                            </b-input-group-prepend>
                                        <b-form-file class="text-left" ref="file" v-model.lazy="row.item.firma" :placeholder="row.item.nombreFirma == '' ?  'Elija un archivo o arrástrelo aquí...' : row.item.nombreFirma" accept=".png,.jpg,.jpeg,image/*" browse-text="Subir" v-on:change="handleFileUpload"></b-form-file>
                                    </b-input-group>
                                </b-form-group>
                            </template>


                            <template #cell(acciones)="param">
                                <b-button @click="quitarActividadActualizar(param)" v-if="datosActualizarEntrega.listaEPPS.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </template>
                        </b-table>
                    </b-col>

                    <b-col md="6">
                        <validation-provider name="nombreResponsable" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre del Responsable:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nombre del responsable" v-model="datosActualizarEntrega.nombreResponsable"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="cargoResponsable" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Cargo del Responsable:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el cargo del responsable" v-model="datosActualizarEntrega.cargoResponsable"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fechaResponsable" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha:" class="mb-2">
                                <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosActualizarEntrega.fechaResponsable"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <!--   ******************************************************      -->
                    <b-col md="6">
                        <validation-provider name="firmaResponsable" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Firma:" class="mb-2">
                                <b-input-group>
                                    <b-input-group-prepend v-if="datosActualizarEntrega.urlFirmaResponsable != ''">
                                        <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="descargarDocumento(datosActualizarEntrega.urlFirmaResponsable)">
                                            <i class="fas fa-download fa-xs"></i>
                                        </b-button>
                                    </b-input-group-prepend>
                                    <b-form-file class="text-left" ref="file" v-model="datosActualizarEntrega.firmaResponsable" :placeholder="datosActualizarEntrega.nombreFirmaResponsable == '' ?  'Elija un archivo o arrástrelo aquí...' : datosActualizarEntrega.nombreFirmaResponsable" accept=".png,.jpg,.jpeg,image/*" browse-text="Subir" v-on:change="handleFileUpload"></b-form-file>
                                </b-input-group>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <!--   ******************************************************      -->

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarEntrega = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import firebase from 'firebase';
const dbEntrega = firebase.firestore().collection('entrega');
export default {
    data() {
        return {
            disabled: false,
            listaEntrega: [],
            camposEntrega: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nombreTrabajador",
                    label: "Trabajador",
                    class: "text-center",
                },
                {
                    key: "areaTrabajo",
                    label: "Área",
                    class: "text-center",
                },
                {
                    key: "cargoTrabajador",
                    label: "Cargo",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],
            cabeceraActividades: [{
                    key: 'descripcion',
                    label: 'Descripción',
                    class: 'text-center'
                }, {
                    key: 'motivo',
                    label: 'Motivo',
                    class: 'text-center'
                }, {
                    key: 'cantidad',
                    label: 'Cantidad',
                    class: 'text-center'
                }, {
                    key: 'fecha',
                    label: 'Fecha',
                    class: 'text-center'
                }, {
                    key: 'firma',
                    label: 'Firma',
                    class: 'text-center'
                }, {
                    key: "acciones",
                    label: "",
                    class: "text-center",
                }
            ],

            comboProyecto: [],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            datosEntrega: {
                idCliente: '',
                tour: '',
                nombreTrabajador: '',
                areaTrabajo: '',
                cargoTrabajador: '',
                dniTrabajador: '',
                nombreResponsable: '',
                cargoResponsable: '',
                fechaResponsable: '',
                firmaResponsable: null,
                nombreFirmaResponsable: '',
                urlFirmaResponsable: '',
                listaEPPS: [{
                    descripcion:'',
                    motivo: '',
                    cantidad: '',
                    fecha: '',
                    firma: null,
                    urlFirma: '',
                    nombreFirma: '',
                }],
            },
            datosActualizarEntrega: {
                idCliente: '',
                tour: '',
                nombreTrabajador: '',
                areaTrabajo: '',
                cargoTrabajador: '',
                dniTrabajador: '',
                nombreResponsable: '',
                cargoResponsable: '',
                fechaResponsable: '',
                firmaResponsable: null,
                nombreFirmaResponsable: '',
                urlFirmaResponsable: '',
                listaEPPS: [{
                    descripcion:'',
                    motivo: '',
                    cantidad: '',
                    fecha: '',
                    firma: null,
                    urlFirma: '',
                    nombreFirma: '',
                }],
              },

            modalRegistrarEntrega: false,
            modalActualizarEntrega: false,
        }
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        //------------------------------------------------------------------------------
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }
            }
        },
        descargarDocumento(url) {
            window.open(url)
        },
        //------------------------------------------------------------------------------
        blurProyecto() {
            this.computedForm.refs.proyecto.validate();
        },
        blurProyectoActualizar() {
            this.computedActualizarForm.refs.proyecto.validate();
        },
        agregarActividad(k) {
            let me = this;
            me.datosEntrega.listaEPPS.push({
                    descripcion:'',
                    motivo: '',
                    cantidad: '',
                    fecha: '',
                    firma: null,
                    urlFirma: '',
                    nombreFirma: '',
            })
        },
        quitarActividad(param) {
            let me = this;
            me.datosEntrega.listaEPPS.splice(param.index, 1);
        },

        agregarActividadActualizar(l) {
            let me = this;
            me.datosActualizarEntrega.listaEPPS.push({
                    descripcion:'',
                    motivo: '',
                    cantidad: '',
                    fecha: '',
                    firma: null,
                    urlFirma: '',
                    nombreFirma: '',
            })
        },
        quitarActividadActualizar(param, l) {
            let me = this;
            me.datosActualizarEntrega.listaEPPS.splice(param.index, 1);
        },

        agregarAyV() {
            let me = this;
            me.datosEntrega.listaEPPS.push({
                    descripcion:'',
                    motivo: '',
                    cantidad: '',
                    fecha: '',
                    firma: null,
                    urlFirma: '',
                    nombreFirma: '',
            })
        },

        listarEntrega() {
            let me = this;
            dbEntrega
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosEntrega.idCliente))
                .onSnapshot((querySnapshot) => {
                    me.listaEntrega = [];
                    querySnapshot.forEach((doc) => {
                        me.listaEntrega.push({
                            idEntrega: doc.id,
                            idCliente: doc.data().idCliente,
                            tour: doc.data().tour,
                            nombreTrabajador: doc.data().nombreTrabajador,
                            areaTrabajo: doc.data().areaTrabajo,
                            cargoTrabajador: doc.data().cargoTrabajador,
                            dniTrabajador: doc.data().dniTrabajador,
                            nombreResponsable: doc.data().nombreResponsable,
                            cargoResponsable: doc.data().cargoResponsable,
                            fechaResponsable: doc.data().fechaResponsable,
                            firmaResponsable: doc.data().firmaResponsable,
                            nombreFirmaResponsable: doc.data().nombreFirmaResponsable,
                            urlFirmaResponsable: doc.data().urlFirmaResponsable,
                            listaEPPS: doc.data().listaEPPS,
                            
                            estado: doc.data().estado,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                        });
                    });
                    me.listaEntrega.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                });
        },

        async registrarEntrega() {
            let me = this;
            me.disabled = true;
            //---------------------------------------------------------------------------
            for (const i in me.datosEntrega.listaEPPS) {
                if (me.datosEntrega.listaEPPS[i].firma != null) {
                    let miliseg = new Date().getTime();
                    try {
                        const file = me.datosEntrega.listaEPPS[i].firma;
                        
                        if (file) {
                            const response = await firebase
                                .storage()
                                .ref(`Entrega_EPPS/Firmas/${file.name}-` + miliseg)
                                .put(file);
                            const url = await response.ref.getDownloadURL();
                            me.datosEntrega.listaEPPS[i].firma = null;
                            me.datosEntrega.listaEPPS[i].nombreFirma = file.name;
                            me.datosEntrega.listaEPPS[i].urlFirma = url;
                        }
                    } catch (error) {
                        me.file = null;
                        me.datosEntrega.listaEPPS[i].nombreFirma = '';
                        me.datosEntrega.listaEPPS[i].urlFirma = '';
                        me.swat('warning', 'No se pudo subir el documento ')
                    }
                }
            }

            for (const i in me.datosEntrega) {
                if (me.datosEntrega.firmaResponsable != null) {
                    let miliseg = new Date().getTime();
                    try {
                        const file = me.datosEntrega.firmaResponsable;
                        //console.log(file)
                        if (file) {
                            const response = await firebase
                                .storage()
                                .ref(`Entrega_EPPS/Responsable/${file.name}-` + miliseg)
                                .put(file);
                            const url = await response.ref.getDownloadURL();
                            me.datosEntrega.firmaResponsable = null;
                            me.datosEntrega.nombreFirmaResponsable = file.name;
                            me.datosEntrega.urlFirmaResponsable = url;
                        }
                    } catch (error) {
                        me.file = null;
                        me.datosEntrega.nombreFirmaResponsable = '';
                        me.datosEntrega.urlFirmaResponsable = '';
                        me.swat('warning', 'No se pudo subir el documento ')
                    }
                }
            }
            //-----------------------------------------------------------------------------
            dbEntrega.add({
                    idCliente: me.datosEntrega.idCliente,
                    listaEPPS: me.datosEntrega.listaEPPS,
                    tour: me.datosEntrega.tour,
                    nombreTrabajador: me.datosEntrega.nombreTrabajador,
                    areaTrabajo: me.datosEntrega.areaTrabajo,
                    cargoTrabajador: me.datosEntrega.cargoTrabajador,
                    dniTrabajador: me.datosEntrega.dniTrabajador,
                    nombreResponsable: me.datosEntrega.nombreResponsable,
                    cargoResponsable: me.datosEntrega.cargoResponsable,
                    fechaResponsable: me.datosEntrega.fechaResponsable,
                    firmaResponsable: me.datosEntrega.firmaResponsable,
                    nombreFirmaResponsable: me.datosEntrega.nombreFirmaResponsable,
                    urlFirmaResponsable: me.datosEntrega.urlFirmaResponsable,
                    estado: 2,
                    fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then((docRef) => {
                    
                    me.swat('success', 'Registrado satisfactoriamente')
                    me.modalRegistrarEntrega = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },
        async actualizarEntrega() {
            let me = this;
            me.disabled = true;
            //--------------------------------------------------------------------------------
            for (const i in me.datosActualizarEntrega.listaEPPS) {
                if (me.datosActualizarEntrega.listaEPPS[i].firma != null) {
                    let miliseg = new Date().getTime();
                    try {
                        const file = me.datosActualizarEntrega.listaEPPS[i].firma;
                        if (file) {
                            const response = await firebase
                                .storage()
                                .ref(`Registro_Limpieza/Firmas/${file.name}-` + miliseg)
                                .put(file);
                            const url = await response.ref.getDownloadURL();
                            me.datosActualizarEntrega.listaEPPS[i].firma = null;
                            me.datosActualizarEntrega.listaEPPS[i].nombreFirma = file.name;
                            me.datosActualizarEntrega.listaEPPS[i].urlFirma = url;
                        }
                    } catch (error) {
                        me.file = null;
                        me.datosActualizarEntrega.listaEPPS[i].nombreFirma = '';
                        me.datosActualizarEntrega.listaEPPS[i].urlFirma = '';
                        me.swat('warning', 'No se pudo subir el documento ')
                    }
                }
            }

            for (const i in me.datosActualizarEntrega) {
                if (me.datosActualizarEntrega.firmaResponsable != null) {
                    let miliseg = new Date().getTime();
                    try {
                        const file = me.datosActualizarEntrega.firmaResponsable;
                        //console.log(file)
                        if (file) {
                            const response = await firebase
                                .storage()
                                .ref(`Entrega_EPPS/Responsable/${file.name}-` + miliseg)
                                .put(file);
                            const url = await response.ref.getDownloadURL();
                            me.datosActualizarEntrega.firmaResponsable = null;
                            me.datosActualizarEntrega.nombreFirmaResponsable = file.name;
                            me.datosActualizarEntrega.urlFirmaResponsable = url;
                        }
                    } catch (error) {
                        me.file = null;
                        me.datosActualizarEntrega.nombreFirmaResponsable = '';
                        me.datosActualizarEntrega.urlFirmaResponsable = '';
                        me.swat('warning', 'No se pudo subir el documento ')
                    }
                }
            }
            //-----------------------------------------------------------------------------------
            dbEntrega.doc(me.datosActualizarEntrega.idEntrega).update({
                    idCliente: me.datosActualizarEntrega.idCliente,
                    listaEPPS: me.datosActualizarEntrega.listaEPPS,
                    tour: me.datosActualizarEntrega.tour,
                    nombreTrabajador: me.datosActualizarEntrega.nombreTrabajador,
                    areaTrabajo: me.datosActualizarEntrega.areaTrabajo,
                    cargoTrabajador: me.datosActualizarEntrega.cargoTrabajador,
                    dniTrabajador: me.datosActualizarEntrega.dniTrabajador,
                    nombreResponsable: me.datosActualizarEntrega.nombreResponsable,
                    cargoResponsable: me.datosActualizarEntrega.cargoResponsable,
                    fechaResponsable: me.datosActualizarEntrega.fechaResponsable,
                    firmaResponsable: me.datosActualizarEntrega.firmaResponsable,
                    nombreFirmaResponsable: me.datosActualizarEntrega.nombreFirmaResponsable,
                    urlFirmaResponsable: me.datosActualizarEntrega.urlFirmaResponsable,
                    estado: 2,
                  
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then(() => {
                    //console.log("Actualizado correctamente");
                    me.swat('success', 'Modificado satisfactoriamente')
                    me.modalActualizarEntrega = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },

        eliminarQuejas(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el registro?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbEntrega.doc(param.item.idEntrega).update({
                            estado: 1,
                            fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                        })
                        .then(() => {
                            me.swat('success', 'Eliminado satisfactoriamente')
                            me.disabled = false;
                        })
                        .catch((error) => {
                            me.disabled = false;
                            me.swat('error', 'Algo salió mal!')
                        });

                }
            })
        },
        
        abrirmodalActualizarEntrega(param) {
            this.datosActualizarEntrega.idEntrega = param.item.idEntrega;
            this.datosActualizarEntrega.tour = param.item.tour;
            this.datosActualizarEntrega.nombreTrabajador = param.item.nombreTrabajador;
            this.datosActualizarEntrega.areaTrabajo = param.item.areaTrabajo;
            this.datosActualizarEntrega.cargoTrabajador = param.item.cargoTrabajador;
            this.datosActualizarEntrega.dniTrabajador = param.item.dniTrabajador;
            this.datosActualizarEntrega.nombreResponsable = param.item.nombreResponsable;
            this.datosActualizarEntrega.cargoResponsable = param.item.cargoResponsable;
            this.datosActualizarEntrega.fechaResponsable = param.item.fechaResponsable;
            this.datosActualizarEntrega.firmaResponsable = param.item.firmaResponsable;
            this.datosActualizarEntrega.nombreFirmaResponsable = param.item.nombreFirmaResponsable;
            this.datosActualizarEntrega.urlFirmaResponsable = param.item.urlFirmaResponsable;
            this.datosActualizarEntrega.listaEPPS = param.item.listaEPPS;
           
            this.datosActualizarEntrega.acciones = param.item.acciones;

            this.modalActualizarEntrega = true
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },

        resetmodalRegistrarEntrega() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosEntrega.tour = '';
            this.datosEntrega.nombreTrabajador = '';
            this.datosEntrega.areaTrabajo = '';
            this.datosEntrega.cargoTrabajador = '';
            this.datosEntrega.dniTrabajador = '';
            this.datosEntrega.nombreResponsable = '';
            this.datosEntrega.cargoResponsable = '';
            this.datosEntrega.fechaResponsable = '';
            this.datosEntrega.firmaResponsable = '';
            this.datosEntrega.nombreFirmaResponsable = '';
            this.datosEntrega.urlFirmaResponsable = '';
            this.datosEntrega.listaEPPS = [{
                    descripcion:'',
                    motivo: '',
                    cantidad: '',
                    fecha: '',
                    firma: null,
                    urlFirma: '',
                    nombreFirma: '',
                }];
            this.datosEntrega.acciones = '';
        },
        resetmodalActualizarEntrega() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedFormActualizar() {
            return this.$refs.observer2;
        },

    },
    watch: {
        modalRegistrarEntrega: function (val) {
            if (val == false) {
                this.resetmodalRegistrarEntrega();
            }
        },
        modalActualizarEntrega: function (val) {
            if (val == false) {
                this.resetmodalActualizarEntrega();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosEntrega.idCliente = this.datosActualizarEntrega.idCliente = user.idCliente;
            this.listarEntrega();
        }
    }
}
</script>